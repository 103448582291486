import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import { Container } from 'components/layout';
import Image from 'components/image';
import Button from 'components/button';

import s from './HeroIntro.scss';

export default class HeroIntro extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    heading2: PropTypes.string,
    text2: PropTypes.string,
    cta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
    cancelHr: PropTypes.bool,
    shouldAnimate: PropTypes.bool,
  }

  static defaultProps = {
    heading: null,
    text: null,
    image: null,
    heading2: null,
    text2: null,
    cta: {},
    cancelHr: false,
    shouldAnimate: true,
  }

  componentDidMount() {
    this.animate();
  }

  animate = () => {
    const t = new TimelineLite({ paused: true });

    const contentItems = this.intro.childNodes;

    t.addLabel('start');

    if (contentItems.length > 0) {
      t.staggerFromTo(contentItems, 1, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0 }, 0.2, 'start');
    }

    if (this.image) {
      t.fromTo(this.image, 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 'start+=0.6');
    }

    if (this.hr) {
      t.fromTo(
        this.hr,
        0.4,
        { autoAlpha: 0, scaleX: 0 },
        { autoAlpha: 1, scaleX: 1, transformOrigin: 'center' },
        'start+=0.6',
      );
    }

    t.addLabel('end');

    if (this.props.shouldAnimate) {
      t.play();
    } else {
      t.seek('end');
    }
  }

  render() {
    const hasHr = !this.props.cancelHr;
    const { heading, text, image, heading2, text2, cta } = this.props;

    const IMAGE_WIDTH = 1545;

    return (
      <div className={s.hero}>
        <div className={s.hero__inner}>
          <Container>
            <div
              className={s.hero__intro}
              ref={(el) => { this.intro = el; }}
            >
              <h1 className={s.hero__heading}>{heading}</h1>
              {text && <p className={s.hero__text}>{text}</p>}
            </div>
          </Container>

          {image &&
            <Container>
              <div className={s.hero__imageWrap} ref={(el) => { this.image = el; }}>
                <div className={s.hero__image}>
                  <Image
                    src={`${image.src}?w=${IMAGE_WIDTH}`}
                    src2x={`${image.src}?w=${IMAGE_WIDTH * 2}`}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                  />
                </div>
              </div>
            </Container>
          }

          {(!image && hasHr) &&
            <hr ref={(el) => { this.hr = el; }} />
          }

          <Container>
            <div className={s.hero__content}>
              {heading2 && <h2 className={s.hero__heading2}>{heading2}</h2>}
              {text2 && <p className={s.hero__text2}>{text2}</p>}
              {cta.url &&
                <Button to={cta.url} color="transparent">
                  {cta.text}
                </Button>
              }
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
