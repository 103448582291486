import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TimelineLite, Linear } from 'gsap';
import 'lib/DrawSVGPlugin';

import s from './Timer.scss';

export default class Timer extends PureComponent {

  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    index: PropTypes.number,
    limit: PropTypes.number.isRequired,
    duration: PropTypes.number,
    play: PropTypes.bool, // send in play onEvent rather than automatically
    pause: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'small']),
  }

  static defaultProps = {
    index: 1,
    duration: 5,
    play: true,
    pause: false,
    size: 'small',
  }

  componentDidMount() {
    this.animate();

    if (this.props.play) {
      this.t.play();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pause !== this.props.pause && this.props.pause) {
      this.t.seek(0).pause();
    }

    if (prevProps.index !== this.props.index && !this.props.pause) {
      // eslint-disable-next-line react/prop-types
      this.t.seek(0).play();
    }

    if (prevProps.play !== this.props.play) {
      if (this.props.play) {
        this.t.play();
      } else {
        // eslint-disable-next-line react/prop-types
        this.t.pause();
      }
    }
  }

  animate = () => {
    this.t = new TimelineLite({ paused: true, onComplete: this.onComplete });
    this.t.set(this.circle, { rotation: -90.01, transformOrigin: 'center', drawSVG: '0%' });
    this.t.fromTo(this.circle, this.props.duration, { drawSVG: '0%' }, { drawSVG: '100%', ease: Linear.easeNone });
  }

  onComplete = () => {
    this.t.seek(0).pause();

    if (this.props.index + 1 === this.props.limit) {
      this.props.onUpdate(0);
    } else {
      this.props.onUpdate(this.props.index + 1);
    }
  }

  formatNumber = i => i.toLocaleString('en-US', { minimumIntegerDigits: 2 });

  render() {
    const { index, limit, size } = this.props;
    const strokeWidth = size === 'large' ? 2 : 4;

    return (
      <div className={s('timer', size)}>
        <svg viewBox="0 0 90 90" className={s.timer__svg}>
          <circle
            className={s.timer__background}
            ref={(el) => { this.circle = el; }}
            r="43"
            cx="45"
            cy="45"
            strokeWidth={strokeWidth}
            fill="none"
          />

          <circle
            ref={(el) => { this.circle = el; }}
            r="43"
            cx="45"
            cy="45"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="none"
          />
        </svg>

        <div className={s.timer__status}>
          <div className={s.timer__current}>
            {size === 'large' ? this.formatNumber(index + 1) : index + 1}
          </div>

          {size === 'large' && (
            <div className={s.timer__limit}>
              {this.formatNumber(limit)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
