import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import HeroIntro from 'components/hero-intro';
import Modules from 'containers/modules';
import GreyArea from 'components/grey-area';
import SEO from 'components/seo';

import JobCta from './components/cta';
import Why from './components/why';
import Carousel from './components/carousel';
import JobListings, { JobListingsCategory } from './components/job-listings';

const JOB_SECTION_TITLE = 'listings';

export default class Jobs extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    jobListings: [],
  }

  componentDidMount() {
    this.fetchJobs();
  }

  async fetchJobs() {
    const res = await fetch('https://boards-api.greenhouse.io/v1/boards/proxy/jobs');

    if (!res.ok) {
      return;
    }

    const json = await res.json();

    this.setState({
      jobListings: _groupBy(json.jobs, 'location.name'),
    });
  }

  render() {
    const { page } = this.props.data;
    const { jobListings } = this.state;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO pathname={pathname} article {...extractMeta(page)}/>
        <HeroIntro
          heading={page.heroHeading}
          text={page.heroText}
          image={{
            src: _get(page.heroImage, 'file.url'),
            alt: _get(page.heroImage, 'description'),
            width: _get(page.heroImage, 'file.details.image.width'),
            height: _get(page.heroImage, 'file.details.image.height'),
          }}
        />
        <LazyLoad once offset={500}>
          <Modules list={[page.aboutProxy]} />
        </LazyLoad>

        <JobCta
          heading={page.joinUsHeading}
          cta={page.joinUsCtaText}
          anchor={JOB_SECTION_TITLE}
        />

        <LazyLoad once offset={500}>
          <Why
            heading={page.whyHeading}
            text={_get(page.whyText, 'text')}
            image={{
              src: _get(page.whyImage, 'file.url'),
              alt: _get(page.whyImage, 'description'),
              width: _get(page.whyImage, 'file.details.image.width'),
              height: _get(page.whyImage, 'file.details.image.height'),
            }}
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Modules list={page.modules} />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Carousel items={page.testimonials} />
        </LazyLoad>

        <div id={JOB_SECTION_TITLE}>
          <GreyArea>
            <JobListings heading={page.listingsHeading}>
              {Object.entries(jobListings).map(([location, jobs]) => (
                <JobListingsCategory
                  key={location}
                  heading={location}
                  data={jobs.map(job => ({
                    position: job.title,
                    location: job.location.name,
                    link: job.absolute_url,
                    id: job.id,
                  }))}
                />
              ))}
            </JobListings>
          </GreyArea>
        </div>

        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}
