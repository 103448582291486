import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';

import Image from 'components/image';

import s from './CarouselItem.scss';

const DURATION = 1000;

export default class CarouselItem extends PureComponent {

  static propTypes = {
    quote: PropTypes.string.isRequired,
    image: PropTypes.string,
    authorName: PropTypes.string,
    authorRole: PropTypes.string,
  }

  static defaultProps = {
    image: '',
    authorName: '',
    authorRole: '',
  }

  onEntering = () => {
    TweenLite.set(
      this.item,
      { opacity: 0.75, x: '-50%', zIndex: 1 },
    );
  }

  onEnter = () => {
    TweenLite.to(
      this.item,
      (DURATION / 1000),
      { opacity: 1, x: '0%' },
    );
  }

  onEntered = () => {
    TweenLite.set(
      this.item,
      { zIndex: 1 },
    );
  }

  onExiting = () => {
    TweenLite.set(
      this.item,
      { zIndex: 0, position: 'absolute' },
    );
  }

  render() {
    const { quote, image, authorName, authorRole, ...props } = this.props;

    return (
      <Transition
        {...props}
        timeout={DURATION}
        unmountOnExit
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExiting={this.onExiting}
      >
        <div className={s.carouselItem} ref={(el) => { this.item = el; }}>
          <div className={s.carouselItem__inner}>
            <div className={s.carouselItem__imageWrap}>
              {image &&
                <div className={s.carouselItem__image}>
                  <Image src={image} alt={authorName || ''} />
                </div>
              }
            </div>

            <div className={s.carouselItem__content}>
              <blockquote className={s.carouselItem__blockquote}>
                <div className={s.carouselItem__quote}>
                  &ldquo;{quote}&rdquo;
                </div>
                <cite className={s.carouselItem__cite}>
                  {authorName && <span className={s.carouselItem__name}>{authorName}</span>}
                  {authorRole && <span className={s.carouselItem__role}>{authorRole}</span>}
                </cite>
              </blockquote>
            </div>
          </div>
        </div>
      </Transition>
    );
  }
}
