import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import Button from 'components/button';
import { Container } from 'components/layout';
import ViewportEnter from 'components/viewport-enter';

import s from './JobCta.scss';

export default class JobCta extends Component {

  static propTypes = {
    anchor: PropTypes.string.isRequired,
    heading: PropTypes.string,
    cta: PropTypes.string,
  }

  static defaultProps = {
    heading: 'Sound good?',
    cta: 'Join us',
  }

  animate = () => {
    const t = new TimelineLite();

    t.addLabel('start');
    t.staggerFromTo(this.inner.childNodes, 1, { y: 40, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, 0.2)
      .fromTo(`.${s.cta__border}`, 0.7, { scaleX: 0, autoAlpha: 0 }, { scaleX: 1, autoAlpha: 1 }, 'start+=0.5');
  }

  render() {
    const { anchor, heading, cta } = this.props;

    return (
      <ViewportEnter onEnter={this.animate}>
        <div className={s.cta}>
          <div className={s.cta__border} />
          <Container>
            <div className={s.cta__inner} ref={(el) => { this.inner = el; }}>
              <h2 className={s.cta__heading}>{heading}</h2>
              <div className={s.cta__ctas}>
                <Button
                  size="large"
                  to={`#${anchor}`}
                  color="red"
                  className={s.cta__button}
                >
                  {cta}
                </Button>
              </div>
            </div>
          </Container>
          <div className={s.cta__border} />
        </div>
      </ViewportEnter>
    );
  }
}
