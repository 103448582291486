import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';
import Leader from 'components/leader';

import s from './JobListings.scss';

export default class JobListings extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node,
  }

  render() {
    const { heading, children } = this.props;

    return (
      <div className={s.jobListings}>
        <Leader heading={heading} centered headingTag="h1" />
        <Container>
          {children}
        </Container>
      </div>
    );
  }
}
