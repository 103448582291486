import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';
import Arrow from 'assets/svg/icons/arrow-rounded.svg';

import s from './JobListingsItem.scss';

const JobListingsItem = ({ position, location, link }) => (
  <div className={s.jobListingsItem}>
    <div className={s.jobListingsItem__content}>
      <h3 className={s.jobListingsItem__position}>{position}</h3>
      <small className={s.jobListingsItem__location}>{location}</small>
    </div>
    <Clickable className={s.jobListingsItem__link} to={link}><Arrow /></Clickable>
  </div>
);

JobListingsItem.propTypes = {
  position: PropTypes.string,
  location: PropTypes.string,
  link: PropTypes.string,
};

export default JobListingsItem;
