import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Segment, { Row } from 'components/layout';

import Image from 'components/image';

import s from './Why.scss';

export default class Why extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: null,
  }

  render() {
    const { heading, text, image } = this.props;

    return (
      <section className={s.why}>
        <Segment container>
          <div className={s.why__content}>
            <Row>
              <h2 className={s.why__heading}>
                {heading}
              </h2>
              <p className={s.why__text}>
                {text}
              </p>
            </Row>
          </div>
          {image &&
            <div className={s.why__image}>
              <Image
                src={image.src}
                alt={image.alt || ''}
                width={image.width}
                height={image.height}
                transition="scale"
              />
            </div>
          }
        </Segment>
      </section>
    );
  }
}
