import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Leader from 'components/leader';

import s from '../../styles/spacing.scss';

export default class GreyArea extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    leaderCentered: PropTypes.bool,
    children: PropTypes.node,
  }

  static defaultProps = {
    heading: '',
    text: '',
    leaderCentered: false,
    children: undefined,
  }

  render() {
    const { heading, text, leaderCentered, children } = this.props;

    return (
      <section className={s.greyArea}>
        {heading && (
          <Leader centered={leaderCentered} headingTag="h1" heading={heading} text={text} className={s.greyArea__intro} />
        )}
        {children}
      </section>
    );
  }
}
