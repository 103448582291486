import React from 'react';
import PropTypes from 'prop-types';

import slugify from 'slugify';

import { Row } from 'components/layout';

import { JobListingsItem } from '.';

import s from './JobListingsCategory.scss';

// Note: this must match what's defined in gatsby-node!
const jobPath = (title) => {
  const slugOptions = {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  };

  return slugify(title, slugOptions);
};

const JobListingsCategory = ({ heading, data }) => (
  <div className={s.jobListingsCategory}>
    <Row>
      <div className={s.jobListingsCategory__hgroup}>
        <h2 className={s.jobListingsCategory__heading}>{heading}</h2>
        <small className={s.jobListingsCategory__count}>{data.length} open position{data.length !== 1 && 's'}</small>
      </div>
      <div className={s.jobListingsCategory__children}>
        {data.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={s.jobListingsCategory__item} key={`${s.jobListingsCategory}-${i}`}>
            <JobListingsItem
              position={item.position}
              location={item.location}
              link={`/jobs/${jobPath(item.position)}?gh_jid=${item.id}`}
            />
          </div>
        ))}
      </div>
    </Row>
  </div>
);

JobListingsCategory.propTypes = {
  data: PropTypes.array,
  heading: PropTypes.string,
};

export default JobListingsCategory;
