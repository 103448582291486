import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Timer from 'components/timer';
import ViewportEnter from 'components/viewport-enter';

import Carousel from './Carousel';
import CarouselItem from './CarouselItem';

import s from './Carousel.scss';

export default class CarouselWrapper extends PureComponent {

  state = {
    activeSlide: 0,
    active: false,
  }

  onUpdate = (i) => {
    this.setState({ activeSlide: i });
  }

  onClick = () => {
    this.setState({ active: false });
  }

  onEnter = () => {
    this.setState({ active: true });
  }

  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quote: PropTypes.shape({
          text: PropTypes.string,
        }).isRequired,
        authorName: PropTypes.string,
        authorRole: PropTypes.string,
        authorPortrait: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    ),
  }

  render() {
    const { items } = this.props;
    const { activeSlide, active } = this.state;

    return (
      <ViewportEnter onEnter={this.onEnter}>
        <Carousel
          activeSlide={activeSlide}
          onUpdate={this.onUpdate}
          onClick={this.onClick}
          timer={
            <Timer
              index={activeSlide}
              limit={items.length}
              onUpdate={this.onUpdate}
              play={active}
              pause={!active}
              size="large"
            />
          }
        >
          {items.map((item, i) => (
            <CarouselItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${s.carousel}-${i}`}
              image={_get(item.authorPortrait, 'file.url')}
              quote={item.quote.text}
              authorName={item.authorName}
              authorRole={item.authorRole}
            />
          ))}
        </Carousel>
      </ViewportEnter>
    );
  }
}
