import React, { cloneElement, Children, Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

import s from './Carousel.scss';

export default class Carousel extends Component {

  static propTypes = {
    children: PropTypes.node,
    activeSlide: PropTypes.number,
    timer: PropTypes.element,
    onUpdate: PropTypes.func,
    onClick: PropTypes.func,
  }

  state = {
    isPause: false,
  };

  onClick = (index) => {
    const { onUpdate, onClick } = this.props;

    onUpdate(index);
    onClick();

    this.setState({ isPause: true });
  }

  render() {
    const { children, timer, activeSlide } = this.props;
    const { isPause } = this.state;
    const slides = Children.toArray(children);

    const arrow = (
      <svg viewBox="0 0 17 33">
        <path
          fill="currentColor"
          d="M.3 31c-.4.4-.4 1.2 0 1.6.4.4 1.1.4 1.6 0l14.8-15.3c.4-.4.4-1.2 0-1.6L1.9.4C1.5 0 .8 0 .3.4-.1.8-.1 1.6.3 2l14 14.5L.3 31z"
        />
      </svg>
    );

    return (
      <div className={s.carousel}>
        <div className={s.carousel__inner}>
          <TransitionGroup className={s.carousel__item}>
            {slides[activeSlide]}
          </TransitionGroup>
        </div>

        <div className={s.carousel__timer}>
          <div className={s(s.carousel__timerInner, { isPause })}>{timer}</div>
        </div>

        <button
          className={s('carousel__arrow', 'prev')}
          disabled={activeSlide === 0}
          onClick={() => this.onClick(activeSlide - 1)}
        >
          {arrow}
        </button>

        <button
          className={s('carousel__arrow', 'next')}
          disabled={(activeSlide + 1) === slides.length}
          onClick={() => this.onClick(activeSlide + 1)}
        >
          {arrow}
        </button>
      </div>
    );
  }
}
