import { graphql } from 'gatsby';
import Jobs from 'routes/jobs/Jobs';

export default Jobs;

export const query = graphql`
  query Jobs {
    page: contentfulPageJobs {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody
      heroImage {
        ...image
      }
      aboutProxy {
        ...moduleList
      }
      joinUsHeading: joinUsCtaTitle
      joinUsCtaText: joinUsCtaButtonText
      whyHeading: whyTitle
      whyText: whyBody {
        text: whyBody
      }
      whyImage {
        ...image
      }
      modules {
        ...moduleList
      }
      testimonials {
        ...moduleList
      }
      listingsHeading: listingsTitle
      cta {
        ...moduleList
      }
    }
  }
`;
